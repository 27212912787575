<template>
    <div class="content mt-5 px-4">
        <div>
            <h2> {{detail.title}} </h2>
        </div>
        <div v-html="detail.content">
        </div>
    </div>
</template>

<script>
    import $api from '@/api/help/index'
    export default {
        name: "active",
        data(){
            return {
                detail:{},
                id:''
            }
        },
        created() {
            let id = this.$route.params.id
            this.id = id
            },
        mounted() {
        this.getarticledetail()
            },
        methods:{
            getarticledetail(){
                $api.getarticledetail(this.id).then(res =>{
                    if(res.code == 500)return
                    this.detail = res.data
                })
            }
        }
    }
</script>

<style scoped lang="scss">
.content{
    width: 1200px;
    @media (max-width: 1200px) {
        width: 100%;
    };
    margin: 0 auto;
}
</style>